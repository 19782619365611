<template>
  <model title="Radio" tag="单选按钮">
    <h3>基础用法</h3>
    <lh-radio fontSize="15px" v-model="selectValue" radioValue="value1">选项1</lh-radio>
    <lh-radio fontSize="20px" v-model="selectValue" radioValue="value2">选项2</lh-radio>
    <lh-radio fontSize="25px" v-model="selectValue" radioValue="value3">选项3</lh-radio>
    <div>
      <lh-collapse>
        <div slot="title">示例代码</div>
        <lh-code-editor v-model="code" :readonly="true"></lh-code-editor>
      </lh-collapse>
    </div>
    <h3>属性参考表</h3>
    <lh-table>
      <lh-tr>
        <lh-th>参数</lh-th>
        <lh-th>说明</lh-th>
        <lh-th>类型</lh-th>
        <lh-th>可选值</lh-th>
        <lh-th>默认值</lh-th>
      </lh-tr>
      <lh-tr>
        <lh-td>value(v-model)</lh-td>
        <lh-td>绑定的值</lh-td>
        <lh-td>-</lh-td>
        <lh-td>-</lh-td>
        <lh-td></lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>radioValue</lh-td>
        <lh-td>组件的值</lh-td>
        <lh-td>-</lh-td>
        <lh-td>-</lh-td>
        <lh-td></lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>fontSize</lh-td>
        <lh-td>字体大小</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>15px</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>margin</lh-td>
        <lh-td>组件外边距</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>2px</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>activeColor</lh-td>
        <lh-td>激活颜色</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>#409EFF</lh-td>
      </lh-tr>
    </lh-table>
  </model>
</template>

<script>
import Model from '../../components/Model.vue'
export default {
  name:"RadioView",
  components: { Model },
  data(){
    return{
      selectValue:"value1",
      code:
        "<template>\n"+
        "<div>\n"+
        "  <lh-radio fontSize=\"15px\" v-model=\"selectValue\" radioValue=\"value1\">选项1</lh-radio>\n"+
        "  <lh-radio fontSize=\"20px\" v-model=\"selectValue\" radioValue=\"value2\">选项2</lh-radio>\n"+
        "  <lh-radio fontSize=\"25px\" v-model=\"selectValue\" radioValue=\"value3\">选项3</lh-radio>\n"+
        "</div>\n"+
        "</template>\n\n"+
        "<script>\n"+
        "export default {\n"+
        "  data(){\n"+
        "    return{\n"+
        "      selectValue:\"value1\"\n"+
        "    }\n"+
        "  }\n"+
        "}\n"+
        "</scr"+"ipt>"
    }
  }

}
</script>

<style>

</style>